<template>
  <div>
    <div class="card">
      <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
      <div class="line"></div>
      <el-form :model="form" :inline="true">
        <el-form-item label="类型名称">
          <el-input placeholder="请输入" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="收支类型"  label-width="100px">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="10">收入</el-radio>
            <el-radio :label="20">支出</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态"  label-width="100px">
          <el-radio-group v-model="form.status">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="10">启用</el-radio>
            <el-radio :label="20">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm">重置</el-button>
      </div>
    </div>
    <div>
      <div class="table">
        <el-table
          border
          :data="tableData.list"
          stripe
          v-loading="loading"
          height="700"
          @row-dblclick="handleDalclick"
          :row-class-name="handleRowIndex"
        >
          <template v-for="(item, index) in columnList">
            <el-table-column
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias === 'status'">
                  <el-switch
                    @change="handleChange($event, scope.row)"
                    :active-value="10"
                    :inactive-value="20"
                    v-model="scope.row.status">
                  </el-switch>
                </template>
                <template v-else>
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column align="center" field_alias="operation" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" style="color: #F56C6C;" @click="handleDele(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class='pagina'>
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout='total, sizes, prev, pager, next, jumper'
          :total='tableData.total'
          :page-size='limit'
          :page-sizes="$store.state.pageSizes"
          @current-change='handleCurrentChange'
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="新增款项"
      :visible.sync="addVisible"
      width="30%"
      :before-close="handleClose">
      <el-form :model="addForm" label-width="80px">
        <el-form-item label="类型名称" l>
          <el-input placeholder="请输入" v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="收支方式">
          <el-radio-group v-model="addForm.type" :disabled="(addForm.id && !addForm.is_edit) ? true : false">
            <el-radio :label="10">收入</el-radio>
            <el-radio :label="20">支出</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            :active-value="10"
            :inactive-value="20"
            v-model="addForm.status">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleAddStreamType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addStreamTypeReq,
} from "@/api/statistics/finance/otherIncome";
import { streamTypeListReq, updateStreamTypeStatusReq, streamTypeDelReq, editStreamTypeReq } from '@/api/statistics/finance/streamType';

export default {
  data() {
    return {
      addForm: {
        status: 10,
        type: 10,
      },
      addVisible: false,
      page: 1,
      limit: this.$store.state.pageSizes[0],
      loading: false,
      form: {
        type: 0,
        status: 0,
      },
      tableData: {
        list: [],
        total: 0,
      },
      columnList: [
        {
          field_text: '序号',
          field_alias: 'index',
          t_width: 80,
        },
        {
          field_text: '收支类型',
          field_alias: 'type_text',
        },
        {
          field_text: '款项名称',
          field_alias: 'name',
        },
        {
          field_text: '状态',
          field_alias: 'status',
        },
        {
          field_text: '添加人',
          field_alias: 'store_user_name',
        },
        {
          field_text: '最新修改时间',
          field_alias: 'update_time',
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleAdd() {
      this.addVisible = true;
      this.addForm = {
        status: 10,
        type: 10,
      };
    },
    handleEdit(row) {
      this.addForm = { ...row };
      this.addVisible = true;
    },
    handleDele(row) {
      this.$confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          streamTypeDelReq({
            id: row.id,
          })
            .then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.getList();
              }
            })
        })
    },
    handleChange(val, row) {
      updateStreamTypeStatusReq({
        id: row.id,
        status: val,
      })
        .then((res) => {
          if (res.code === 1) {
            this.getList();
          }
        })
    },
    // 新增款项名称
    handleAddStreamType() {
      if (this.addForm.id) {
        editStreamTypeReq({
        id: this.addForm.id,
        name: this.addForm.name,
        type: this.addForm.type,
        status: this.addForm.status,
      })
        .then((res) => {
          if (res.code === 1) {
            this.handleClose();
            this.$message.success('编辑成功');
            this.getList();
          }
        });
        return;
      }
      addStreamTypeReq({
        ...this.addForm,
      })
        .then((res) => {
          if (res.code === 1) {
            this.handleClose();
            this.$message.success('新增成功');
            this.getList();
          }
        });
    },
    handleClose() {
      this.addVisible = false;
    },
    resetForm() {
      this.form = {
        type: 0,
        status: 0,
      };
      this.page = 1;
      this.getList();
    },
    // 搜索
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    handleRowIndex({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    getList() {
      this.loading = true;
      streamTypeListReq({
        ...this.form,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleDalclick() {},
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    handleSizeChange(limit) {
      this.limit = limit;
      this.getList();
    },
  },
}
</script>

<style lang="less" scoped>
.btns {
  margin-left: auto;
}
.card {
  display: flex;
}
/deep/ .el-form-item {
  margin-bottom: 0px;
}
</style>
