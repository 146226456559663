import { post } from '@/utils/request';

// 收入列表
const streamTypeListReq = (data) => post({
  url: '/store/order.SundryCtl/streamTypeList',
  data: data,
});

// 启动/禁用
const updateStreamTypeStatusReq = (data) => post({
  url: '/store/order.SundryCtl/updateStreamTypeStatus',
  data: data,
});

// 删除
const streamTypeDelReq = (data) => post({
  url: '/store/order.SundryCtl/streamTypeDel',
  data: data,
});

// 编辑
const editStreamTypeReq = (data) => post({
  url: '/store/order.SundryCtl/editStreamType',
  data: data,
});

export {
  editStreamTypeReq,
  streamTypeDelReq,
  streamTypeListReq,
  updateStreamTypeStatusReq,
}
